import React from 'react'
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import './Navbar.css'

function Navbar() {
    return (
        <nav className="navbar">
            <div className="navbar-left">
                <AcUnitRoundedIcon />
                <span>geloodev</span>
            </div>

            <div className="navbar-right">
                <li className="nav-li"><a href="#my-works">my-works</a></li>
                <li className="nav-li"><a href="#about-me">about-me</a></li>
                <li className="nav-li"><a href="#my-links">my-links</a></li>
                <select className="language-selection">
                    <option value="en"> 🇬🇧 </option>
                    <option value="br"> 🇧🇷 </option>
                </select>
            </div>

            <input type="checkbox" id="sidebar-active" />
            <label className="open-menu-btn" for="sidebar-active">
                <i class='bx bx-menu'></i>
            </label>

            <label id="overlay" for="sidebar-active"></label>
            <ul className="mobile-menu">
                <label className='close-menu-btn' for="sidebar-active">
                    <i class="bx bx-x"></i>
                </label>
                <div className='mobile-menu-items'>
                    <li className="nav-li"><a href="#my-works">my-works</a></li>
                    <li className="nav-li"><a href="#about-me">about-me</a></li>
                    <li className="nav-li"><a href="#my-links">my-links</a></li>
                </div>
            </ul>
        </nav>
    )
}

export default Navbar;

