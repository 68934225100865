import React, { useContext } from 'react'
import './AboutMe.css'
import { AnimationContext } from '../../context/AnimationProvider';

function AboutMe() {

    const { addToRefs } = useContext(AnimationContext)

    return (
        <section id="about-me" className="about-me-section hidden" ref={addToRefs}>
            <h2 className="section-title"><span>#</span>about-me</h2>

            <div className="about-me-content hidden" ref={addToRefs}>
                <div className="about-me-text">
                    Hello, i’m Ângelo Holandini!
                    <br /><br />
                    I am a fullstack developer
                    based in Sumaré, São Paulo, 🇧🇷 Brazil. I am 21 ye
                    ars old and passionate about the world of technology.
                    <br />
                    <br />
                    I can develop a specialized website to showcase your
                    product to the world, or even complete systems for your
                    company, making your dream come true!
                    <br />
                    <br />
                    If you want an ideal product to meet your needs, contact
                    me, I am sure we will make a good deal!
                </div>

                <div className="skills">
                    <div className="skills-group">
                        <div className="skills-group-title">
                            skills
                        </div>
                        <div className="skills-group-text">
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-java"></i>
                                <span>java</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-spring-boot"></i>
                                <span>spring boot</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-c-plain"></i>
                                <span>c</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-lua-plain"></i>
                                <span>lua</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-react-plain"></i>
                                <span>react</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-vuejs-plain"></i>
                                <span>vue.js</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-javascript-plain">
                                </i>
                                <span>javascript</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-html5-plain"></i>
                                <span>html</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-css3-plain"></i>
                                <span>css</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-postgresql-plain">
                                </i>
                                <span>postgresql</span>
                            </div>
                        </div>
                    </div>

                    <div className="skills-group">
                        <div className="skills-group-title">
                            tools
                        </div>
                        <div className="skills-group-text">
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-git"></i>
                                <span>git</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-github"></i>
                                <span>github</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-docker-plain"></i>
                                <span>docker</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-figma-plain"></i>
                                <span>figma</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-linux-plain"></i>
                                <span>linux</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-archlinux-plain"></i>
                                <span>arch</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-neovim-plain"></i>
                                <span>neovim</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutMe;
