import React from 'react'

export const ProjectsContext = React.createContext()

function ProjectsProvider(props) {

    const projects = [
        {
            imgUrl: "/projects-images/my-portfolio.png",
            title: "My Portfolio",
            description: "Made with Javascript, React, HTML and CSS.",
            link: "https://www.geloodev.me"
        }
    ]

    return (
        <ProjectsContext.Provider
            value={{ projects }}>
            {props.children}
        </ProjectsContext.Provider>
    )
}

export default ProjectsProvider;
