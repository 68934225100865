import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <footer>
            <p>© Copyright 2024. Made with ❤️️‍️ by <span>geloodev</span></p>
        </footer>
    )
}

export default Footer;
