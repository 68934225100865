import React from 'react'
import LaunchIcon from '@mui/icons-material/Launch'
import './Project.css'

function Project({ imgUrl, title, description, link }) {
    return (
        <div className="project">
            <div className="project-img">
                <img src={imgUrl} alt="" />
            </div>

            <div className="project-text">
                <h4>{title}</h4>
                <span>{description}</span>
                <a href={link}>
                    <div className="project-btn">
                        <span>See project</span>
                        <LaunchIcon />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Project;
