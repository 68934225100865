import React from 'react'
import './Hero.css'

function Hero() {
    return (
        <div className="hero">
            <div className="hero-title">
                Hi! I am
                <br />
                <span id="my-name">Ângelo Holandini</span>,
                <br />
                <span id="fullstack">fullstack developer</span>
            </div>
            <div className="my-profile">
                <img src="my-picture.jpg" alt="" />
                <div className="my-profile-text">
                    Ângelo Holandini <br />
                    <span>@geloodev</span>
                </div>
            </div>
        </div>
    )
}

export default Hero;
