import "/node_modules/flag-icons/css/flag-icons.min.css"
import './App.css'
import Navbar from './components/Navbar/Navbar'
import Hero from './components/Hero/Hero'
import MyWorks from './components/MyWorks/MyWorks'
import AboutMe from './components/AboutMe/AboutMe'
import MyLinks from './components/MyLinks/MyLinks'
import Footer from './components/Footer/Footer'
import ProductsProvider from './context/ProjectsProvider'
import AnimationProvider from './context/AnimationProvider'

function App() {

    return (
        <div className="App">
            <div className="container">
                <AnimationProvider>
                    <Navbar />
                    <Hero />
                    <ProductsProvider>
                        <MyWorks />
                    </ProductsProvider>
                    <AboutMe />
                    <MyLinks />
                    <Footer />
                </AnimationProvider>
            </div>
        </div>
    );
}

export default App;
