import { useContext } from 'react'
import './MyWorks.css'
import Project from '../Project/Project'
import { ProjectsContext } from '../../context/ProjectsProvider'
import { AnimationContext } from '../../context/AnimationProvider'

function MyWorks() {

    const { projects } = useContext(ProjectsContext)
    const { addToRefs } = useContext(AnimationContext)

    return (
        <section id="my-works" className="my-works-section hidden" ref={addToRefs}>
            <div className="my-works-title">
                <h2 className="section-title"><span>#</span>my-works</h2>
            </div>
            <div className="projects hidden" ref={addToRefs}>
                {projects.map((project) => (
                    <Project
                        imgUrl={project.imgUrl}
                        title={project.title}
                        description={project.description}
                        link={project.link}
                    />
                ))}
            </div>
        </section>
    )
}

export default MyWorks;
